import React, { useEffect } from "react";
import { useSignIn, useSignUp, useAuth, AuthenticateWithRedirectCallback } from "@clerk/clerk-react";

const SSOCallbackPage = () => {
  const { signIn } = useSignIn();
  const { signUp, setActive } = useSignUp();
  const { isSignedIn } = useAuth();

  AuthenticateWithRedirectCallback();
  // useEffect(() => {
  //   const handleSSOCallback = async () => {
  //     await AuthenticateWithRedirectCallback();
  //     window.location.href = "/azure";
  //   };

  //   handleSSOCallback();
  // }, []);


  useEffect(() => {
    const handleSSOCallback = async () => {
      try {
        // Authenticate with redirect callback

        // Check if user needs to be created
        if (signIn?.firstFactorVerification?.status === "transferable") {
          console.log("Creating a new account...");
          const res = await signUp.create({ transfer: true });

          if (res.status === "complete") {
            console.log("New account created. Signing in...");
            setActive({ session: res.createdSessionId });
            window.location.href = "/azure"; // Redirect after success
          } else {
            console.error("Sign-up incomplete:", res);
          }
        } else if (isSignedIn) {
          console.log("User is already signed in. Redirecting...");
          window.location.href = "/azure";
        }
      } catch (error) {
        console.error("Error handling SSO callback:", error);
      }
    };

    handleSSOCallback();
  }, [signIn, signUp, setActive, isSignedIn]);
  if (isSignedIn) {
    window.location.href = "/azure";
  }
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <h1 className="text-xl font-semibold">Processing your SSO callback...</h1>
    </div>
  );
};

export default SSOCallbackPage;
